<template>
  <v-row class="ma-0">
    <v-col>
      <v-card max-width="580" class="mx-auto text-center px-16 py-13">
        <v-img class="mx-auto" max-width="162" src="@/assets/img/msg.svg"></v-img>
        <div class="text-h5 font-weight-bold mt-10">Email confirmation re-sent sucessfully</div>
        <p class="opasity--text mt-3">
          <v-row>
            <v-col cols="8" class="mx-auto">Check your Inbox and click on the “Confirm my email” link.</v-col>
          </v-row>
        </p>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
};
</script>
